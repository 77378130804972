import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { CustomerService } from "../../services/CustomerService";
import { toast } from "react-toastify";
import { constants } from "../../Constants";
import { AuthService } from "../../services/AuthService";
import DealerDocumentUploadHandler from "../../component/image-manager/UserDealerAadharCard";
import { APP_URL, IMAGE_BASE_URL } from "../../config/Config";
import { Helmet } from "react-helmet-async";

export default function Register() {
  const navigate = useNavigate();
  const getUser = AuthService.GetUserAuth();
  const getUserId = (getUser && getUser.user._id) || null;
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [inputData, setInputData] = useState({
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    customer_password: "",
    customer_confirm_password: "",
    customer_image: "",
    customer_is_dealer: false,
    customer_dealer_aadhar_card: "",
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    customer_name,
    customer_email,
    customer_phone,
    customer_password,
    customer_confirm_password,
    customer_image,
    customer_is_dealer,
    customer_dealer_aadhar_card,
  } = inputData;

  const fetchData = async () => {
    try {
      const response = await new CustomerService().GetCustomerDetails(
        getUserId
      );
      const result = response?.data?.result;

      if (result) {
        setInputData({
          customer_name: result.customer_name,
          customer_email: result.customer_email,
          customer_phone: result.customer_phone,
          customer_is_dealer: result.customer_is_dealer,
          customer_dealer_aadhar_card: result.customer_dealer_aadhar_card,
          customer_image: result.customer_image,
        });

        setValue("customer_name", result.customer_name);
        setValue("customer_email", result.customer_email);
        setValue("customer_phone", result.customer_phone);
      }
    } catch (error) {
      console.error(constants.messages.errors.read, error);
    }
  };

  useEffect(() => {
    if (!getUserId) return;

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserId]);

  async function formHandler(data) {
    try {
      setLoading(true);

      const payload = new FormData();

      payload.append("customer_name", data.customer_name);
      if (!getUserId) {
        payload.append("customer_email", data.customer_email);
      }
      payload.append("customer_phone", data.customer_phone);
      if (getUserId) {
        payload.append("customer_image", customer_image);
      }
      if (!getUserId) {
        payload.append("customer_password", data.customer_password);
      }
      if (customer_is_dealer) {
        payload.append("customer_is_dealer", customer_is_dealer);
        payload.append(
          "customer_dealer_aadhar_card",
          customer_dealer_aadhar_card
        );
      }

      if (getUserId) {
        const response = await new CustomerService().UpdateCustomer(
          getUserId,
          payload
        );

        if (response?.data?.success) {
          toast.success(response?.data?.message, {
            icon: <i className="fa-solid fa-pencil"></i>,
          });
          fetchData();
        } else {
          toast.warning(response?.data?.message, {
            icon: <i className="fa-solid fa-triangle-exclamation"></i>,
          });
        }
      } else {
        const response = await new CustomerService().RegisterCustomer(payload);

        if (response?.data?.success) {
          toast.success(response?.data?.message, {
            icon: <i className="fa-solid fa-plus"></i>,
          });
          reset();
          navigate("/user/login");
        } else {
          toast.warning(response?.data?.message, {
            icon: <i className="fa-solid fa-triangle-exclamation"></i>,
          });
        }
      }
    } catch (error) {
      console.error(`${constants.messages.errors.create} ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
    <Helmet>
        <title>Register Your Account - Potter Pumps</title>
        <meta name="description" content="Create your Potter Pumps account to explore high-quality pumps and electrical solutions. Register today for a seamless shopping experience."/>
        <meta name="keywords" content="User Registration, Create Account, Potter Pumps Sign Up, Electrical Solutions, Pump Manufacturer"/>
        <meta name="author" content="Potter Pump Pvt. Ltd." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`${APP_URL}/user/register`} />

        {/* Open Graph (Facebook & LinkedIn) */}
        <meta property="og:title" content="Register - Potter Pumps" />
        <meta property="og:description" content="Sign up for a Potter Pumps account to access premium-quality pumps and electrical solutions." />
        <meta property="og:image" content={`${IMAGE_BASE_URL}/register-banner.jpg`} />
        <meta property="og:url" content={`${APP_URL}/user/register`} />
        <meta property="og:type" content="website" />

        {/* Twitter Card (For Twitter Previews) */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Register - Potter Pumps" />
        <meta name="twitter:description" content="Join Potter Pumps today to shop for reliable pumps and electrical solutions." />
        <meta name="twitter:image" content={`${IMAGE_BASE_URL}/register-banner.jpg`} />

        {/* Structured Data for SEO (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "User Registration",
            "url": `${APP_URL}/user/register`,
            "description": "Create a new account at Potter Pumps to access premium-quality pumps and electrical solutions.",
            "publisher": {
              "@type": "Organization",
              "name": "Potter Pumps",
              "logo": `${IMAGE_BASE_URL}/logo.png`
            }
          })}
        </script>
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="card user-card-1">
            <form onSubmit={handleSubmit(formHandler)}>
              <div className="row gy-4">
                <div className="col-12">
                  <h1>{getUserId ? "Update Profile" : "Register"}</h1>
                </div>
                {getUserId && (
                  <>
                    <div className="col-12">
                      <DealerDocumentUploadHandler
                        className="user-img-placeholder"
                        name="Profile Image"
                        placeholderImg="default-image-md.png"
                        imageValue={customer_image}
                        onResult={(imagePath) =>
                          setInputData({
                            ...inputData,
                            customer_image: imagePath,
                          })
                        }
                      />
                    </div>
                    <div className="col-12">
                      <hr />
                    </div>
                  </>
                )}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="customer_name">Name</label>
                    {errors.customer_name && (
                      <span className="error-message">
                        {errors.customer_name.message}
                      </span>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      id="customer_name"
                      defaultValue={customer_name}
                      {...register("customer_name", {
                        required: "Name is required",
                      })}
                      aria-invalid={errors.customer_name ? "true" : "false"}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="customer_email">Email</label>
                    {errors.customer_email && (
                      <span className="error-message">
                        {errors.customer_email.message}
                      </span>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      id="customer_email"
                      defaultValue={customer_email}
                      {...register("customer_email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      aria-invalid={errors.customer_email ? "true" : "false"}
                      disabled={getUserId ? true : false}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="customer_phone">Phone</label>
                    {errors.customer_phone && (
                      <span className="error-message">
                        {errors.customer_phone.message}
                      </span>
                    )}
                    <input
                      type="tel"
                      className="form-control"
                      id="customer_phone"
                      defaultValue={customer_phone}
                      {...register("customer_phone", {
                        required: "Phone is required",
                      })}
                      aria-invalid={errors.customer_phone ? "true" : "false"}
                    />
                  </div>
                </div>
                {!getUserId && (
                  <>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="customer_password">Password</label>
                        {errors.customer_password && (
                          <span className="error-message">
                            {errors.customer_password.message}
                          </span>
                        )}
                        <div className="password-input-wrapper">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            className="form-control"
                            id="customer_password"
                            defaultValue={customer_password}
                            {...register("customer_password", {
                              required: "Password is required",
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message:
                                  "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character",
                              },
                            })}
                            aria-invalid={
                              errors.customer_password ? "true" : "false"
                            }
                          />
                          <div
                            className="icon"
                            onClick={() => {
                              setIsPasswordVisible(!isPasswordVisible);
                            }}
                          >
                            {isPasswordVisible ? (
                              <i className="fa-regular fa-eye-slash"></i>
                            ) : (
                              <i className="fa-regular fa-eye"></i>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="customer_confirm_password">
                          Confirm Password
                        </label>
                        {errors.customer_confirm_password && (
                          <span className="error-message">
                            {errors.customer_confirm_password.message}
                          </span>
                        )}
                        <input
                          type="password"
                          className="form-control"
                          id="customer_confirm_password"
                          defaultValue={customer_confirm_password}
                          {...register("customer_confirm_password", {
                            required: "Confirm Password is required",
                            validate: (value) =>
                              value === watch("customer_password") ||
                              "Passwords do not match",
                          })}
                          aria-invalid={
                            errors.customer_confirm_password ? "true" : "false"
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-12">
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        checked={customer_is_dealer}
                        onChange={(e) => {
                          setInputData({
                            ...inputData,
                            customer_is_dealer: e.target.checked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        You Are Dealer
                      </label>
                    </div>
                  </div>
                </div>
                <>
                  {customer_is_dealer && (
                    <>
                      <DealerDocumentUploadHandler
                        name="Aadhar Card"
                        placeholderImg="aadhar-card-placeholder.jpg"
                        imageValue={customer_dealer_aadhar_card}
                        onResult={(imagePath) =>
                          setInputData({
                            ...inputData,
                            customer_dealer_aadhar_card: imagePath,
                          })
                        }
                      />
                    </>
                  )}
                </>
                <div className="col-12 d-flex gap-3 mt-4">
                  <button
                    type="submit"
                    className="btn btn-blue"
                    disabled={loading}
                  >
                    {getUserId ? "Update" : "Register"}
                  </button>
                  {!getUserId && (
                    <div className="text-center">
                      <NavLink to="/user/login" className="btn btn-black">
                        Login
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
