export const APP_LIVE_URL = "https://potterpumps.com";
export const APP_LOCAL_URL = "http://localhost:5001";
export const APP_URL = ['localhost', '192.168.0.157'].includes(window.location.hostname) ? APP_LOCAL_URL : APP_LIVE_URL;

export const SERVER_LIVE_URL = "https://potterpumps.onrender.com";
export const SERVER_LOCAL_URL = "http://localhost:5003";
export const SERVER_URL = window.location.hostname === "localhost" ? SERVER_LOCAL_URL : SERVER_LIVE_URL;
export const PUBLIC_IMAGE_PATH = `${SERVER_URL}/public/uploads/`;

export const IMAGE_BASE_URL = `${APP_URL}/images`;
export const BROCHURE_BASE_URL = `${APP_URL}/brochure`;