import React from "react";
import { NavLink } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config/Config";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer>
        <div className="container py-5">
          <div className="row gy-5 gy-lg-0">
            <div className="col-lg-3 d-flex align-items-center">
              <div className="logo">
                <img
                  src={`${IMAGE_BASE_URL}/logo-white.png`}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <h5>Quick Links</h5>
              <ul className="footer-menu">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us">About</NavLink>
                </li>
                <li>
                  <NavLink to="/products">Products</NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h5>Social Links</h5>
              <ul className="footer-menu">
                <li>
                  <NavLink target="_blank" to="https://instagram.com/potterpumpmotor">Instagram</NavLink>
                </li>
                <li>
                  <NavLink target="_blank" to="https://www.facebook.com/potterpumpmotor">Facebook</NavLink>
                </li>
                <li>
                  <NavLink target="_blank" to="https://wa.me/916351298021?text=Hello%2C%20I%20visited%20your%20website%20and%20I%20would%20like%20to%20know%20details%20of%20your%20products">Whatsapp</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <ul className="contact-info">
                <li className="align-items-start">
                  <i className="fa-solid fa-location-dot"></i>
                  <address className="mb-0">
                    Shed No-1, Jeet industrial Area, <br/>Plot No: 211, Swati Park Main Road,
                    Opp. New Swati Park, Kothariya,
                    Rajkot, Gujarat - 360004
                  </address>
                </li>
                <li>
                  <i className="fa-solid fa-phone"></i>
                  <div>
                    <a href="tel:+916351298021">+91 63512 98021</a>
                    <br />
                    <a href="tel:+919913084962">+91 99130 84962</a>
                  </div>
                </li>
                <li>
                  <i className="fa-solid fa-envelope"></i>
                  <a href="mailto:potterpump.motor@gmail.com">
                    potterpump.motor@gmail.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright py-4 text-center">
          <div className="container">
            <p className="mb-0">
              &copy;{currentYear} Potter. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
