import React from "react";
import { IMAGE_BASE_URL } from "../config/Config";
import InnerPageHeading from "../component/pages/InnerPageHeading";
import { Helmet } from "react-helmet-async";

export default function AboutUs() {
  return (
    <>
     <Helmet>
        <title>About Us - Potter Pumps | Leading Pump Manufacturer</title>
        <meta name="description" content="Discover Potter Pumps, a leading manufacturer of energy-efficient Openwell, Submersible, and Self-Priming Pumps. Trusted since 2015." />
        <meta name="keywords" content="Potter Pumps, Openwell Pumps, Submersible Pumps, Self-Priming Pumps, Pump Manufacturer, Energy-Efficient Pumps" />
        <meta name="author" content="Potter Pumps" />

        {/* Open Graph Meta Tags (For Facebook & LinkedIn) */}
        <meta property="og:title" content="About Potter Pumps | Leading Pump Manufacturer" />
        <meta property="og:description" content="Learn about Potter Pumps, a trusted name in high-quality, energy-efficient pump manufacturing since 2015." />
        <meta property="og:image" content={`${IMAGE_BASE_URL}/banner-img-1.png`} />
        <meta property="og:url" content="https://potterpumps.com/about-us" />
        <meta property="og:type" content="website" />

        {/* Twitter Card (For Twitter Previews) */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Potter Pumps | Leading Pump Manufacturer" />
        <meta name="twitter:description" content="Potter Pumps has been a pioneer in energy-efficient and durable pump solutions since 2015." />
        <meta name="twitter:image" content={`${IMAGE_BASE_URL}/banner-img-1.png`} />

        {/* Structured Data for SEO (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Potter Pumps",
            "url": "https://potterpumps.com",
            "logo": `${IMAGE_BASE_URL}/logo.png`,
            "description": "Leading manufacturer of Openwell, Submersible, and Self-Priming Pumps since 2015.",
            "sameAs": [
              "https://www.facebook.com/potterpumps",
              "https://www.instagram.com/potterpumps",
            ]
          })}
        </script>
      </Helmet>

      <InnerPageHeading heading="About Us" />
      <section className="section-padding p-last-0">
        <div className="container">
          <div className="row gx-lg-5 flex-column-reverse flex-lg-row align-items-center">
            <div className="col-lg-6">
              <h2 className="heading-2 mb-3 mb-lg-4">About Potter Pump</h2>
              {/* <p className="large-text-1">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla
                blanditiis velit corrupti accusamus natus cum?
              </p> */}
              <p>
                Established in 2015, Potter Pumps is a trusted name in the pump
                manufacturing industry. A proud initiative under Bhagwati
                Electric, we have grown from manufacturing Openwell Pumps to
                offering a wide range of products, including Submersible Pumps,
                Self-Priming Pumps, and precision-engineered parts.
              </p>
              <p>
                Our focus has always been on delivering innovative,
                energy-efficient, and high-quality products to meet the diverse
                needs of our customers.
              </p>
            </div>
            <div className="col-lg-6 mb-4 mb-0 d-flex justify-content-center">
              <img
                src={`${IMAGE_BASE_URL}/banner-img-1.png`}
                alt=""
                className="img-fluid hero-banner-img"
                width="450"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding bg-light">
        <div className="container">
          <div className="row gy-4 gy-lg-0">
            <div className="col-lg-4">
              <div className="card card-about-1">
                <img
                  src={`${IMAGE_BASE_URL}/target.svg`}
                  alt=""
                  className="img-fluid"
                />
                <h3>Mission</h3>
                <p>
                  To deliver reliable, energy-efficient, and high-quality
                  pumping solutions while fostering sustainability and
                  innovation.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-about-1">
                <img
                  src={`${IMAGE_BASE_URL}/vision.svg`}
                  alt=""
                  className="img-fluid"
                />
                <h3>Vision</h3>
                <p>
                  To be a global leader in the pump manufacturing industry by
                  continually setting new benchmarks for quality and efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-about-1">
                <img
                  src={`${IMAGE_BASE_URL}/value.svg`}
                  alt=""
                  className="img-fluid"
                />
                <h3>Values</h3>
                <p>
                  We uphold excellence, sustainability, and integrity by
                  delivering quality, energy-efficient solutions with honesty
                  and transparency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding p-last-0">
        <div className="container">
          <div className="row gy-4 gy-lg-0 gx-lg-5 align-items-center">
            <div className="col-lg-6">
              <img
                src={`${IMAGE_BASE_URL}/about-img-2.jpg`}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="heading-2 mb-3 mb-lg-4">Company Branding</h2>
              {/* <p className="large-text-1">
              </p> */}
              <p>
                The name Potter Pumps embodies our commitment to superior
                craftsmanship and technological advancement. As a brand of
                Bhagwati Electric, we are dedicated to building lasting
                relationships with our clients through consistent product
                quality and exceptional service.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
