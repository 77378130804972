import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import CartMenu from "./CartMenu";
import { IMAGE_BASE_URL, BROCHURE_BASE_URL } from "../../config/Config";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  let [activeMenu, setActiveMenu] = useState(false);
  let [activeHeader, setActiveHeader] = useState(false);
  let [scrollProgress, setScrollProgress] = useState(0); // Progress bar state

  const [searchParam, setSearchParam] = useSearchParams("");
  const getSearch = searchParam.get("search");

  const pathname = location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      let activeClass = window.scrollY > 0;
      setActiveHeader(activeClass);

      // Calculate scroll percentage
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollTop / docHeight) * 100;

      setScrollProgress(scrollPercent); // Update progress bar width
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [pathname]);

  useEffect(() => {
    document.body.style.overflow = activeMenu ? "hidden" : "unset";
  }, [activeMenu]);

  const searchHandler = (e) => {
    const value = e.target.value;
    if (value) {
      navigate(`/products?search=${value}`);
    } else {
      searchParam.delete("search");
      setSearchParam(searchParam);
    }
  };

  return (
    <>
      <div className={`overlay ${activeMenu ? "active-menu" : ""}`} onClick={() => setActiveMenu(!activeMenu)} ></div>

      <header className={`main-header ${activeHeader ? "active" : ""} ${activeMenu ? "active-menu" : ""}`}>
        <div className="container">
          <div className="header-top-belt">
            <div className="logo">
              <NavLink to="/"> <img src={`${IMAGE_BASE_URL}/logo.png`} alt="" className="img-fluid" /></NavLink>
            </div>
            <div className="header-top-belt-center">
              {!activeHeader && (
                <div className="header-search-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for products"
                    defaultValue={getSearch}
                    onChange={searchHandler}
                  />
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div>
              )}
              <ul className="menu">
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/about-us">About</NavLink></li>
                <li><NavLink to="/products">Products</NavLink></li>
                <li><NavLink to="/contact-us">Contact</NavLink></li>
                <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                <li><Link target="_blank" to={`${BROCHURE_BASE_URL}/potter-pumps-brochure.pdf`}>Download Brochure</Link></li>
              </ul>
            </div>
            <div className="header-top-belt-right">
              <ul> <ProfileMenu /><CartMenu /></ul>
              <div className="menubar" onClick={() => setActiveMenu(!activeMenu)}>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Scroll Progress Bar */}
      <div className="progress fixed-top" style={{ height: "5px", backgroundColor: "transparent" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${scrollProgress}%`,
            backgroundColor: "var(--theme-body-bg-blue-color )",
            transition: "width 0.3s ease-out",
          }}
        ></div>
      </div>
    </>
  );
}
