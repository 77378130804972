import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import InnerPageHeading from "../../component/pages/InnerPageHeading";
import ProductCart1 from "../../component/card/ProductCart1";
import Pagination from "@mui/material/Pagination";
import { ProductService } from "../../services/ProductService";
import { constants } from "../../Constants";
import { useSearchParams } from "react-router-dom";
import { PUBLIC_IMAGE_PATH, APP_URL } from "../../config/Config";

export default function Products() {
  const [productData, setProductData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [param, setParam] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);

  const getPage = Number(param.get("page") || 1);
  const getSearch = param.get("search");

  async function fetchProductData() {
    try {
      let query = `?page=${getPage}&limit=${constants.pagiLimit}`;

      if (getSearch) {
        query += `&search=${getSearch}`;
      }

      const response = await new ProductService().ProductLists(query);
      const products = response.data.result;

      setProductData(products);

      // Extract unique categories from products for SEO links
      const uniqueCategories = [
        ...new Map(
          products.map((product) => [
            product.product_category_id,
            {
              id: product.product_category_id,
              name: product.product_category_name,
              slug: product.category?.[0]?.category_slug || "",
            },
          ])
        ).values(),
      ];
      setCategories(uniqueCategories);

      const pagiTotalPage = response.data.paginationInfo.totalPages;
      setTotalPages(pagiTotalPage);

      if (getPage > pagiTotalPage) {
        param.set("page", 1);
        setParam(param);
      }

      if (!getSearch) {
        document.documentElement.scrollTo({
          top: 250,
          left: 0,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.error(`${constants.messages.errors.read} ${error.message}`);
    }
  }

  function pagiHandler(e, page) {
    param.set("page", page);
    setParam(param);
  }

  useEffect(() => {
    fetchProductData();
  }, [getPage, getSearch]);

  // Generate structured data (JSON-LD) for SEO
  const generateStructuredData = () => {
    const structuredProducts = productData.map((product) => ({
      "@type": "Product",
      name: product.product_name,
      description: product.product_description,
      image: `${PUBLIC_IMAGE_PATH}${product.product_image}`,
      url: `${APP_URL}/products/${product.product_slug}/${product._id}`,
      offers: {
        "@type": "Offer",
        priceCurrency: "INR",
        price: product.product_price,
        availability: product.product_stock_qty > 0 ? "InStock" : "OutOfStock",
      },
    }));

    return {
      "@context": "https://schema.org",
      "@type": "ItemList",
      itemListElement: structuredProducts.map((product, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: product,
      })),
    };
  };

  return (
    <>
      <Helmet>
        <title>Our Products - Best Quality & Affordable Prices</title>
        <meta name="description" content={`Explore our wide range of high-quality products, including ${categories.map((cat) => cat.name).join(", ")}. Find the best deals on premium items at affordable prices.`}/>

        <meta name="keywords" content="High Quality Pumps | High Pressure Pumps | Open Well Pumps | Self Priming Pumps | Texmo Type Pumps | The best Products, Affordable Price" />
        <link rel="canonical" href={`${APP_URL}/products`} />
        <script type="application/ld+json">
          {JSON.stringify(generateStructuredData())}
        </script>
      </Helmet>

      <InnerPageHeading heading="Our Products" />

      {/* Category Links for Internal Linking */}
      <section className="section-padding">
        <div className="container">
          {/* <div className="row mb-4">
            <div className="col-12">
              <h4>Shop by Category:</h4>
              <ul className="category-links">
                {categories.map((category) => (
                  <li key={category.id}>
                    <Link to={`/category/${category.slug}`}>{category.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}

          {/* Product Listing */}
          <div className="row gy-5">
            {productData &&
              productData.length > 0 &&
              productData.map((value) => (
                <div className="col-md-6 col-lg-3" key={`product_${value._id}`}>
                  <ProductCart1 value={value} />
                </div>
              ))}
          </div>

          {/* Pagination */}
          <div className="row mt-5">
            <div className="col-12">
              <Pagination count={totalPages} page={getPage} size="large" onChange={pagiHandler} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
