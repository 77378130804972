import React from "react";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { NavLink } from "react-router-dom";
import HomeProductList from "../component/products/HomeProductList";
import { Helmet } from "react-helmet-async";

export default function Home() {
  return (
    <>
      <Helmet>
        <meta name="description" content="Potter Pumps offers high-quality pump solutions for industrial and residential use. Browse our products, learn about us, and get in touch today." />
        <meta name="keywords" content="pumps, industrial pumps, water pumps, Potter Pumps, high-quality pumps" />
        <meta name="author" content="Potter Pumps" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Potter Pumps - Leading Pump Solutions" />
        <meta property="og:description" content="Find high-quality pumps for various applications. Explore our range and get the best pump solutions." />
        <meta property="og:image" content="https://www.potterpumps.com/images/logo.png" />
        <meta property="og:url" content="https://www.potterpumps.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Potter Pumps - Best Pump Solutions" />
        <meta name="twitter:description" content="Explore our high-quality pump solutions. Contact us today!" />
        <meta name="twitter:image" content="https://www.potterpumps.com/images/logo.png" />
        <link rel="canonical" href="https://www.potterpumps.com/" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <meta name="robots" content="index, follow" />
        <link rel="sitemap" type="application/xml" title="Sitemap" href="https://potterpumps.onrender.com/sitemap.xml" />

        <script type="application/ld+json">
          {
            JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Potter Pumps",
              "url": "https://www.potterpumps.com",
              "logo": "https://www.potterpumps.com/images/logo.png",
              "sameAs": [
                "https://www.facebook.com/potterpumpmotor",
                "https://instagram.com/potterpumpmotor"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-635-129-8021",
                "contactType": "Contact number"
              },
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://www.potterpumps.com/"
              },
              "hasPart": [
                {
                  "@type": "WebPage",
                  "name": "products",
                  "url": "https://www.potterpumps.com/products"
                },
                {
                  "@type": "WebPage",
                  "name": "About Us",
                  "url": "https://www.potterpumps.com/about-us"
                },
                {
                  "@type": "WebPage",
                  "name": "Contact Us",
                  "url": "https://www.potterpumps.com/contact-us"
                }
              ]
            })}
        </script>
      </Helmet>
      <section className="hero-banner">
        <Swiper spaceBetween={30} creativeEffect={{ prev: { shadow: true, translate: [0, 0, -400], }, next: { translate: [0, 0, -400], }, }}
          speed={1500} loop={true} rewind={true} parallax={true} navigation={true} pagination={{ type: "progressbar", clickable: true }}
          modules={[Parallax, Navigation, Pagination]} >
          <SwiperSlide>
            <div className="container">
              <div className="row gy-4 gx-lg-5 justify-content-between">
                <div className="col-lg-6 d-flex justify-content-center align-items-center " >
                  <img src="images/banner-img-1.png" alt="Openwell Pump - potterpumps" className="img-fluid hero-banner-img" />
                </div>
                <div className="col-lg-6">
                  <div className="hero-banner-content">
                    <span className="small-heading mb-3 animate-fade-in" data-swiper-parallax="-300"> Premium Build</span>
                    <h2 className="heading-1 mb-3 animate-slide-up" data-swiper-parallax="-220"> Reliable Openwell <span>Pump</span> Systems </h2>
                    <p className="large-text-1 mb-4 animate-slide-right" data-swiper-parallax="-150">
                      Designed for maximum efficiency and durability to meet your
                      toughest water management needs.
                    </p>
                    <NavLink to="/products" className="btn btn-black mt-2 animate-grow" data-swiper-parallax="-100"><span>Shop Now</span></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="container">
              <div className="row gy-4 gx-lg-5 justify-content-between">
                <div className="col-lg-6 d-flex justify-content-center align-items-center fade-in">
                  <img src="images/banner-img-1.png" alt="Energy Efficient Pump - potterpumps" className="img-fluid hero-banner-img" />
                </div>
                <div className="col-lg-6">
                  <div className="hero-banner-content">
                    <span className="small-heading mb-3 animate-fade-in" data-swiper-parallax="-300"> Energy Efficient</span>
                    <h2 className="heading-1 mb-3 animate-slide-up" data-swiper-parallax="-220"> Advanced <span>Performance</span> Technology</h2>
                    <p className="large-text-1 mb-4 animate-slide-right" data-swiper-parallax="-150">
                      Reduce energy costs with our state-of-the-art, eco-friendly
                      pumping solutions.
                    </p>
                    <NavLink to="/products" className="btn btn-black mt-2 animate-grow" data-swiper-parallax="-100"><span>Explore Now</span></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xxl-9">
              <span className="small-heading">About Company</span>
              <h1 className="mb-2 mt-1">
                Setting a tradition of excellence since 2015.
              </h1>
              <p className="large-text-2 mb-4">
                Welcome to PotterPumps, a brand that has set a benchmark in the
                industry since 2015. From our beginnings with Openwell Pumps, we
                have continually expanded our expertise to include Submersible
                Pumps, Self-Priming Pumps, and their components.
              </p>
              <NavLink to="/about-us" className="btn btn-black">
                About Us
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="small-heading">Our Products</span>
              <h2 className="heading-2 mb-0 mt-1">Our Products</h2>
            </div>
            <div className="col-lg-6 d-none d-lg-flex justify-content-end align-items-center">
              <NavLink to="/products" className="btn btn-black">
                View All
              </NavLink>
            </div>
            <div className="col-12 mt-5">
              <HomeProductList />
            </div>
            <div className="col-12 d-flex justify-content-center d-lg-none mt-4 pt-2">
              <NavLink to="/products" className="btn btn-black">
                View All
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="justify-content-center pb-5">
            <h1>Why choose us?</h1>
          </div>
          <div className="row align-items-center flex-column-reverse flex-lg-row">
            <div className="col-lg-9 col-xxl-8">
              <h2 className="mb-2 mt-1">ISO 9001:2015</h2>
              <p className="large-text-2 mb-0">
                we are proud to be ISO 9001 certified, a globally recognized
                standard for quality management. This certification highlights
                our commitment to improving performance, and delivering
                high-quality products. It reflects our dedication to excellence.
              </p>
            </div>
            <div className="col-lg-3 col-xxl-4 d-flex align-items-center justify-content-center justify-content-lg-end mb-4 mb-lg-0">
              <img src="images/iso-9001-2015-logo.png" alt="ISO 9001:2015 Certified" className="img-fluid" width="220" />
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-center flex-column-reverse flex-lg-row-reverse">
            <div className="col-lg-9 col-xxl-8">
              <h2 className="mb-2 mt-1">Best Quality</h2>
              <p className="large-text-2 mb-0">
                At Potter Pumps, quality is at the heart of what we do. Using
                energy-saving pure copper wires and high-quality brass materials
                for bushings, we ensure every product meets rigorous standards
                for efficiency and durability.
              </p>
            </div>
            <div className="col-lg-3 col-xxl-4 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
              <img src="images/best-quality-logo.png" alt="best quality potter pumps sticker" className="img-fluid" width="220" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
